import React, { useEffect, useRef, useState } from 'react'
import Avatar from '@mui/material/Avatar';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import { blue } from '@mui/material/colors';
import { ReactComponent as ChevronDown } from '../../assets/Icons/chevron-down.svg'
import { ReactComponent as ChevronRight } from '../../assets/Icons/chevron-right.svg'
import { ReactComponent as UserIcon } from '../../assets/Icons/user.svg'
import { ReactComponent as UsersIcon } from '../../assets/Icons/users.svg'
import { ReactComponent as RewardDarkIcon } from "../../assets/Icons/rewardDarkIcon.svg";
import { ReactComponent as ShareIcon } from '../../assets/Icons/share-menu.svg'
import { ReactComponent as LogOutIcon } from '../../assets/Icons/log-out.svg'
import { ReactComponent as CogIcon } from '../../assets/Icons/cog.svg'
import paths from '../../constants/paths';
import { useAuth } from '../../contexts/Auth';
import { useBasicInfo } from '../../contexts/BasicInfo';
import { useGeneralProfile } from '../../contexts/Organization';
import { colleagues, treatment_program } from '../../constants/common';
import { Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SendToFriendModal from '../SendToFriendModal';
import Link from "@mui/material/Link";
import { trackClickEvent } from '../../mixpanel';

const UserMenu = ({ toggleInvitationModalVisability }) => {
  const { data } = useBasicInfo();
  const { facilityInfo } = useGeneralProfile();
  const { logout, account_type, updateAuthState } = useAuth();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [openShare, setOpenShare] = useState(false);

  const info = account_type === treatment_program ? facilityInfo : data;
  const isMentalHealthProvider = !Object.keys(info)?.length || (account_type === treatment_program && info?.roles?.includes('mental_health_provider'));
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleOpenShareModal = () => {
    trackClickEvent(
      'Open Share Modal',
      { button: 'Share', page: 'Homepage', component: 'Usermenu' }
    )
    setOpenShare(true);
  }

  const handleCloseShareModal = () => {
    setOpenShare(false);
  }

  const handleClick = () => {
    trackClickEvent(
      'Open Invite Modal',
      { buttonName: 'Invite', page: 'Homepage', component: 'Usermenu' }
    )
    toggleInvitationModalVisability();
  }


  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const handleLogout = (event) => {
    logout();
    handleClose(event);
    trackClickEvent(
      'User Logout',
      { page: 'Homepage', component: 'Header', button: 'Logout' }
    );
  }

  const switchToUser = () => {
    setOpen(false);
    localStorage.setItem('account_type', 'user')
    updateAuthState('account_type', 'user')
    navigate(paths.home)
  }

  const switchToProgram = () => {
    setOpen(false);
    localStorage.setItem('account_type', treatment_program)
    updateAuthState('account_type', treatment_program)
    navigate(paths.home)
  }

  const renderRole = (role) => {
    if (role === 'team_admin')
      return 'Admin'
    if (role === 'team_assistant')
      return 'Assistant Admin'
    if (role === 'mental_health_provider')
      return 'Mental Health Provider'
    return ''
  }

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const menuItemStyle = {
    fontSize: "14px",
    fontWeight: "500 !important",
    lineHeight: "22px",
    marginLeft: "8px"
  }
  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', border: 0, p: 0, backgroundColor: 'transparent', height: '100%', gap: 1.5 }} component='button'
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Avatar
          sx={{ width: 30, height: 30, fontSize: 13 }}
          src={info?.main_image}
        />
        <Box
          sx={{
            color: 'border.dark',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <ChevronDown />
        </Box>
      </Box>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{
          zIndex: 1
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'right top',
            }}
          >
            <Paper sx={{
              border: '1px solid',
              borderColor: 'border.main',
              boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
              borderRadius: 1.5
            }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  sx={{ pt: 3, px: 2, pb: 2 }}
                >
                  <Box px={1}>
                    {(info?.user_info || info?.program_info)?.has_switcher ? (
                      <>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', mb: 2, cursor: 'pointer' }}
                          onClick={switchToUser}
                        >
                          <Avatar
                            sx={{ width: 48, height: 48, backgroundColor: blue[50], color: blue[400], mr: 1 }}
                            src={account_type === treatment_program ? info?.user_info?.user_profile_image : info?.main_image}
                          />
                          <Box mr={3}>
                            <Typography variant='h6' component='h3' textTransform='capitalize'>
                              {info.first_name} {info.last_name}
                            </Typography>
                            <Typography variant='caption' sx={{ color: 'text.secondary' }}>
                              {info.email}
                            </Typography>
                          </Box>
                          {account_type !== treatment_program && <Checkbox checked sx={{ ml: 'auto' }} />}
                        </Box>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                          onClick={switchToProgram}
                        >
                          <Avatar
                            sx={{ width: 48, height: 48, backgroundColor: blue[50], color: blue[400], mr: 1 }}
                            src={account_type === treatment_program ? info?.main_image : info?.program_info?.treatment_program_image}
                          />
                          <Box mr={3}>
                            <Typography variant='h6' component='h3' textTransform='capitalize'>
                              {account_type === treatment_program ? info?.info?.facility_name : info?.program_info?.treatment_program_name}
                            </Typography>
                            <Typography variant='caption' sx={{ color: 'text.secondary' }}>
                              {account_type === treatment_program ? renderRole((info.roles || [])[1]) : renderRole(info?.program_info?.role)}
                            </Typography>
                          </Box>
                          {account_type === treatment_program && <Checkbox checked sx={{ ml: 'auto' }} />}
                        </Box>
                      </>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                          sx={{ width: 48, height: 48, backgroundColor: blue[50], color: blue[400], mr: 1 }}
                          src={info?.main_image}
                        />
                        <Box mr={3}>
                          <Typography sx={{ fontSize: "14px", fontWeight: 400 }} textTransform='capitalize'>
                            {account_type === treatment_program ? info?.info?.facility_name : `${info.first_name} ${info.last_name}`}
                          </Typography>
                          <Link style={{ color: '#0C8CE9', fontSize: "15px", fontWeight: 500, textDecoration: "none" }} className="view-profile-text" href={paths.userProfile(info?.uuid, account_type)}>
                            {account_type === treatment_program ? 'View Business Profile' : 'View Profile'}
                          </Link>
                        </Box>
                        <Box
                          sx={{
                            color: 'border.dark',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            ml: 'auto'
                          }}
                        >
                          <ChevronRight />
                        </Box>
                      </Box>
                    )}
                    <Divider sx={{ my: 2 }} />
                  </Box>
                  <MenuItem sx={{ p: 0 }}>
                    <ListItemButton
                      onClick={handleClose}
                      href={account_type === treatment_program ? paths.profileOrganization : paths.profileBasic}
                      sx={{ p: 1 }}
                      disabled={isMentalHealthProvider}
                    >
                      <UserIcon />
                      <Typography variant='body1' className="menu-Item-header">
                        Edit Profile
                      </Typography>
                    </ListItemButton>
                  </MenuItem>
                  <MenuItem sx={{ p: 0 }}>
                    <ListItemButton onClick={handleClose} href={paths.network(colleagues)} sx={{ p: 1 }}>
                      <UsersIcon />
                      <Typography variant='body1' component='span' className="menu-Item-header ">
                        Search providers
                      </Typography>
                    </ListItemButton>
                  </MenuItem>
                  <MenuItem sx={{ p: 0 }}>
                    <ListItemButton onClick={handleClose} href={paths.settingsAccount} sx={{ p: 1 }}>
                      <CogIcon />
                      <Typography variant='body1' className="menu-Item-header ">
                        Account settings
                      </Typography>
                    </ListItemButton>
                  </MenuItem>
                  <Divider sx={{ my: 2 }} />
                  <MenuItem sx={{ p: 0 }}>
                    <ListItemButton onClick={handleOpenShareModal} sx={{ p: 1 }}>
                      <ShareIcon />
                      <Typography variant='body1' className="menu-Item-header ">
                        Share profile
                      </Typography>
                    </ListItemButton>
                  </MenuItem>
                  <MenuItem sx={{ p: 0 }}>
                    <ListItemButton onClick={toggleInvitationModalVisability} sx={{ p: 1 }}>
                      <RewardDarkIcon />
                      <Typography variant='body1' className="menu-Item-header ">
                        Invite & get free month
                      </Typography>
                    </ListItemButton>
                  </MenuItem>
                  <Divider sx={{ my: 2 }} />
                  <MenuItem sx={{ p: 0 }}>
                    <ListItemButton onClick={handleLogout} sx={{ p: 1 }}>
                      <LogOutIcon />
                      <Typography variant='body1' component='span' sx={{ ...menuItemStyle, color: 'error.main' }}>
                        Log out
                      </Typography>
                    </ListItemButton>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <SendToFriendModal
        open={openShare}
        onClose={handleCloseShareModal}
        data={info}
      />
    </>
  )
}

export default UserMenu