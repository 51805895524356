import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import { ReactComponent as FileIcon } from '../../assets/Icons/file-text.svg'
import { ReactComponent as EditIcon } from '../../assets/Icons/edit-3.svg'
import { useSpecialties } from '../../contexts/specialties';
import { LOADING, treatment_program } from '../../constants/common';
import { useAuth } from '../../contexts/Auth';


const ProfileSpecialties = () => {
    const { fetchUserSpecialties, specialties, handleOpenDrawer } = useSpecialties();
    const { account_type } = useAuth();
    
    useEffect(() => {
      fetchUserSpecialties({ account_type: account_type === treatment_program ? treatment_program : 'user' });
    }, [])

    return (
        <>
            <Paper
                sx={{
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FileIcon width={24} height={24} />
                        <Typography variant='h4' ml={1}>Specialties</Typography>
                    </Box>
                    {specialties.userSpecialties.length > 0 ? (
                        <IconButton
                            sx={{
                                p: 0.75
                            }}
                            onClick={handleOpenDrawer}
                        >
                            <EditIcon />
                        </IconButton>
                    ) : (
                        <Button
                            variant='contained'
                            size='large'
                            sx={{ minWidth: 150 }}
                            onClick={handleOpenDrawer}
                        >
                            Add New
                        </Button>
                    )}
                </Box>

                {specialties.userSpecialties.length > 0 && (
                    <Divider />
                )}
                <Grid container spacing={2.5}>
                    {specialties.userSpecialties.length > 0 && (
                        <Grid item xs={12}>
                            <Typography variant='h5'>Types of Specialties</Typography>
                        </Grid>
                    )}
                    {specialties.fetchSpecialtiesStatus === LOADING ? (
                        <>
                            <Grid item xs={6}>
                                <Skeleton animation="wave" />
                            </Grid>
                            <Grid item xs={6}>
                                <Skeleton animation="wave" />
                            </Grid>
                            <Grid item xs={6}>
                                <Skeleton animation="wave" />
                            </Grid>
                            <Grid item xs={6}>
                                <Skeleton animation="wave" />
                            </Grid>
                            <Grid item xs={6}>
                                <Skeleton animation="wave" />
                            </Grid>
                            <Grid item xs={6}>
                                <Skeleton animation="wave" />
                            </Grid>
                        </>
                    ) : (
                        specialties.userSpecialties.map((item) => (
                            <Grid item xs={6} key={item.id}>
                                {item.name}
                            </Grid>
                        ))
                    )}
                </Grid>
            </Paper>

            
        </>
    )
}

export default ProfileSpecialties;