import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as EditIcon } from '../../assets/Icons/edit-3.svg';
import { ReactComponent as ThermometerIcon } from '../../assets/Icons/thermometer.svg';
import AgesSelection from '../../components/AgesSelection';
import CheckBox from '../../components/CheckBox';
import {
  LOADING,
  SUCCESS,
  has_video,
  inperson,
  psypact,
  treatment_program
} from '../../constants/common';
import { useAuth } from '../../contexts/Auth';
import { useBasicInfo } from '../../contexts/BasicInfo';
import { useClientServed } from '../../contexts/ClientServed';
import { useGeneralProfile } from '../../contexts/Organization';
import sortAgeRanges from '../../utils/sortAges';

const ProfileClientsServed = () => {
  const { account_type } = useAuth();
  const [state, setState] = useState({
    age_served: [],
    notify_favorite_colleagues: 0,
    inperson_session: 0,
    has_video_provided: 0,
    psypact_provider: 0,
    currently_served: [],
    client_types: []
  });
  const [error, setError] = useState({ ages_served: false, sessions_offered: false });
  const { fetchInfo, data } = useBasicInfo();
  const { fetchFacilityInfo, facilityInfo } = useGeneralProfile();
  const info = account_type !== treatment_program ? data : facilityInfo;
  const {
    fetchUserClientTypesStatus,
    handleOpenClientsServedDrawer,
    editAgesServed,
    editAgesServedStatus,
    updateClientTypesState
  } = useClientServed();


  useEffect(() => {
    if (account_type !== treatment_program) fetchInfo();
  }, [account_type]);

  useEffect(() => {
    if (editAgesServedStatus === SUCCESS) {
      updateClientTypesState('editAgesServedStatus', '');
      account_type !== treatment_program ? fetchInfo() : fetchFacilityInfo();
    }
  }, [editAgesServedStatus, account_type, fetchInfo, fetchFacilityInfo, updateClientTypesState]);

  useEffect(() => {
    if (info) {
      updateState({
        age_served: info?.info?.age_served?.trim() ? info.info.age_served.split(',') : [],
        notify_favorite_colleagues: info?.info?.notify_favorite_colleagues || 0,
        inperson_session: info?.info?.inperson_session || 0,
        has_video_provided: info?.info?.has_video_provided || 0,
        psypact_provider: info?.info?.psypact_provider || 0,
        currently_served: info?.currently_served || [],
        client_types: info?.client_types || [],
      });
    }
  }, [info]);


  const updateState = (obj) => {
    setState((prevState) => ({
      ...prevState,
      ...obj
    }));
  };

  const handleSaveAgesServed = () => {
    if (!state.age_served.length) {
      setError(prev => ({ ...prev, ages_served: true }));
      return;
    }

    const sessionsOffered = ['inperson_session', 'psypact_provider', 'has_video_provided'].some(
      (session) => state[session] === 1
    );

    if (!sessionsOffered && account_type !== treatment_program) {
      setError(prev => ({ ...prev, sessions_offered: true }));
      return;
    }

    const payload = {
      ...state,
      ages: state.age_served.length > 0 ? sortAgeRanges(state.age_served).join(',') : [],
    };
    if (account_type === treatment_program) delete payload.age_served;
    editAgesServed(payload, account_type !== treatment_program ? 'info' : 'program-info');
  };

  const handleCheckBox = (ev) => {
    updateState({ [ev.target.name]: ev.target.checked ? 1 : 0 });
    if (ev.target.checked) {
      setError(prev => ({ ...prev, sessions_offered: false }));
    }
  };

  const renderClientTypes = (title, data) => (
    <>
      <Grid item xs={12}>
        <Typography variant='h5'>{title}</Typography>
      </Grid>
      {fetchUserClientTypesStatus === LOADING
        ? Array.from({ length: 6 }).map((_, index) => (
          <Grid item xs={6} key={index}>
            <Skeleton animation='wave' />
          </Grid>
        ))
        : data.map((item) => (
          <Grid item xs={6} key={item.id}>
            {item.name}
          </Grid>
        ))}
    </>
  );


  return (
    <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
      {account_type !== treatment_program && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <ThermometerIcon width={24} height={24} />
              <Typography variant='h4' ml={1}>Clients Served</Typography>
            </Box>
            <Typography variant='body1'>Please select the types of clients you work with.</Typography>
          </Box>
          {state?.client_types?.length > 0 ? (
            <IconButton sx={{ p: 0.75 }} onClick={handleOpenClientsServedDrawer}>
              <EditIcon />
            </IconButton>
          ) : (
            <Button variant='contained' size='large' sx={{ minWidth: 150 }} onClick={handleOpenClientsServedDrawer}>
              Add New
            </Button>
          )}
        </Box>
      )}
      {state?.client_types?.length > 0 && account_type !== treatment_program && <Divider />}
      {state?.client_types?.length > 0 && account_type !== treatment_program && (
        <Grid container spacing={2.5}>
          {renderClientTypes('Client Types', state?.client_types)}
        </Grid>
      )}
      {account_type !== treatment_program && <Divider />}
      <AgesSelection data={state} setData={updateState} isInvalid={error['ages_served']} />
      {account_type !== treatment_program && (
        <>
          <Divider />
          <Typography variant="h5">Sessions Offered</Typography>
          <CheckBox
            label={has_video.label}
            name={has_video.value}
            onChange={handleCheckBox}
            checked={Boolean(state.has_video_provided)}
          />
          <CheckBox
            label={inperson.label}
            name={inperson.value}
            onChange={handleCheckBox}
            checked={Boolean(state.inperson_session)}
          />
          <CheckBox
            label={psypact.label}
            name={psypact.value}
            onChange={handleCheckBox}
            checked={Boolean(state.psypact_provider)}
          />
          {error['sessions_offered'] && (
            <Typography variant="caption" color="error">
              Please select one or more session type.
            </Typography>
          )}
        </>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <LoadingButton
          variant='contained'
          sx={{ minWidth: 150 }}
          onClick={handleSaveAgesServed}
          loading={editAgesServedStatus === LOADING}
        >
          Save
        </LoadingButton>
      </Box>
    </Paper>
  );
};

export default ProfileClientsServed;
