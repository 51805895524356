import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as EditIcon } from '../../../assets/Icons/edit-3.svg'
import AboutMeDrawer from './AboutMeDrawer';
import { useBasicInfo } from '../../../contexts/BasicInfo';
import { useGeneralProfile } from '../../../contexts/Organization';
import { useAuth } from '../../../contexts/Auth';
import { treatment_program } from '../../../constants/common';
const AboutMe = () => {
  const { data, fetchStatus } = useBasicInfo();
  const { facilityInfo, fetchFacilityStatus } = useGeneralProfile();
  const { account_type } = useAuth();
  const [open, setOpen] = useState(false)

  const info = account_type === treatment_program ? facilityInfo : data;
  const status = account_type === treatment_program ? fetchFacilityStatus : fetchStatus;

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpen = () => {
    setOpen(true);
  }

  return (
    <>
      <Box
        sx={{
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'border.main',
          p: 3,
          borderRadius: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 1.5
          }}
        >
          <Typography variant='h5'>{account_type === treatment_program ? 'About The Program' : 'About Me'}</Typography>
          <IconButton
            sx={{ p: 0.375 }}
            onClick={handleOpen}
            disabled={status !== 'SUCCESS'}
          >

            <EditIcon />
          </IconButton>
        </Box>
        <Typography variant='body2' color='text.secondary'   sx={{ whiteSpace: 'pre-line' }}>
          {info?.info?.about_me}
        </Typography>
      </Box>
      <AboutMeDrawer
        open={open}
        onClose={handleClose}
      />
    </>
  )
}

export default AboutMe;