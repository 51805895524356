import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { ReactComponent as CheckIcon } from '../../assets/Icons/check-filled.svg'
import { useBasicInfo } from '../../contexts/BasicInfo';
import { useTreatment } from '../../contexts/teamentModalities';
import { useSpecialties } from '../../contexts/specialties';
import paths from '../../constants/paths';
import { useDegrees } from '../../contexts/Degrees';
import { useFees } from '../../contexts/Fees';
import { useClientServed } from '../../contexts/ClientServed';

const ProfileProgressCard = ({ progress }) => {
    const { data } = useBasicInfo();
    const { handleOpenDrawer } = useTreatment();
    const { handleOpenDrawer: handleOpenSepcialtiesDrawer } = useSpecialties();
    const { handleOpenClientsServedDrawer } = useClientServed();
    const { handleOpen } = useDegrees();
    const { handleOpenFeesDrawer } = useFees();

    function openModalitiesDrawer() {
        handleOpenDrawer()
    }

    function openClientsTypesDrawer() {
        handleOpenClientsServedDrawer()
    }

    function addNewDegree() {
        handleOpen();
    }

    return (
        <Paper sx={{ py: 3 }}>
            <Box sx={{ px: 3 }}>
                <Typography variant='body2' sx={{ fontWeight: 700, mb: 2 }}>Your Profile</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                    <Typography variant='captionTitle' sx={{ color: 'text.secondary' }}>Completion</Typography>
                    <Typography variant='captionTitle'>{Math.round((progress?.length / 8) * 100)}%</Typography>
                </Box>
                <LinearProgress variant="determinate" value={(progress?.length / 8) * 100} color='warning' />
            </Box>
            <Divider sx={{ my: 2, borderBottomWidth: 2 }} />
            <Box sx={{ px: 1.5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ color: 'primary.main', display: 'flex' }}>
                            <CheckIcon />
                        </Box>
                        <Typography variant='body2' sx={{ color: 'text.secondary' }}>Basic Information</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ color: 'primary.main', display: 'flex' }}>
                            <CheckIcon />
                        </Box>
                        <Typography variant='body2' sx={{ color: 'text.secondary' }}>Address</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ color: (Boolean(data?.info?.office_hours_time) || Boolean(data?.info?.office_hours_day)) ? 'primary.main' : 'secondary.main', display: 'flex' }}>
                            <CheckIcon />
                        </Box>
                        <Typography variant='body2' sx={{ color: 'text.secondary' }}>Office Hours</Typography>
                    </Box>
                    {!(Boolean(data?.info?.office_hours_time) || Boolean(data?.info?.office_hours_day)) && (
                        <Link variant='caption' href={paths.profileAvailability}>
                            Add
                        </Link>
                    )}
                    
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ color: (data?.client_types || [])?.length > 0 ? 'primary.main' : 'secondary.main', display: 'flex' }}>
                            <CheckIcon />
                        </Box>
                        <Typography variant='body2' sx={{ color: 'text.secondary' }}>Clients Served</Typography>
                    </Box>
                    <Button
                        size='small'
                        sx={{
                            p: 0,
                            minWidth: 0
                        }}
                        onClick={openClientsTypesDrawer}
                    >
                        <Typography variant='caption'>
                            {(data?.client_types || [])?.length < 1 ? 'Add' : 'Edit'}
                        </Typography>
                    </Button>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ color: (data?.specialties || [])?.length > 0 ? 'primary.main' : 'secondary.main', display: 'flex' }}>
                            <CheckIcon />
                        </Box>
                        <Typography variant='body2' sx={{ color: 'text.secondary' }}>Specialties</Typography>
                    </Box>
                    <Button
                        size='small'
                        sx={{
                            p: 0,
                            minWidth: 0
                        }}
                        onClick={handleOpenSepcialtiesDrawer}
                    >
                        <Typography variant='caption'>
                            {(data?.specialties || [])?.length < 1 ? 'Add' : 'Edit'}
                        </Typography>
                    </Button>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ color: (data?.treatment_modalities || [])?.length > 0 ? 'primary.main' : 'secondary.main', display: 'flex' }}>
                            <CheckIcon />
                        </Box>
                        <Typography variant='body2' sx={{ color: 'text.secondary' }}>Treatment Modalities</Typography>
                    </Box>
                    <Button
                        size='small'
                        sx={{
                            p: 0,
                            minWidth: 0
                        }}
                        onClick={openModalitiesDrawer}
                    >
                        <Typography variant='caption'>
                            {(data?.treatment_modalities || [])?.length < 1 ? 'Add' : 'Edit'}
                        </Typography>
                    </Button>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ color: (data?.degrees || [])?.length > 0 ? 'primary.main' : 'secondary.main', display: 'flex' }}>
                            <CheckIcon />
                        </Box>
                        <Typography variant='body2' sx={{ color: 'text.secondary' }}>Degree</Typography>
                    </Box>
                    <Button
                        size='small'
                        sx={{
                            p: 0,
                            minWidth: 0
                        }}
                        onClick={addNewDegree}
                    >
                        <Typography variant='caption'>
                            Add
                        </Typography>
                    </Button>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ color: (data?.fees || [])?.length > 0 ? 'primary.main' : 'secondary.main', display: 'flex' }}>
                            <CheckIcon />
                        </Box>
                        <Typography variant='body2' sx={{ color: 'text.secondary' }}>Fees</Typography>
                    </Box>
                    <Button
                        size='small'
                        sx={{
                            p: 0,
                            minWidth: 0
                        }}
                        onClick={handleOpenFeesDrawer}
                    >
                        <Typography variant='caption'>
                            {(data?.fees || [])?.length < 1 ? 'Add' : 'Edit'}
                        </Typography>
                    </Button>
                </Box>
            </Box>

            <Divider sx={{ my: 2, borderBottomWidth: 2 }} />

            <Box sx={{ px: 3 }}>
                <Button
                    color='secondary'
                    variant='outlined'
                    fullWidth
                    sx={{
                        color: 'primary.main'
                    }}
                    href={paths.profileBasic}
                >
                    Complete your profile
                </Button>
            </Box>
        </Paper>
    )
}

export default ProfileProgressCard