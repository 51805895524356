import paths from "./paths";
import { ReactComponent as GridIcon } from '../assets/Icons/grid.svg';
import { ReactComponent as AwardIcon } from '../assets/Icons/award.svg';
import { ReactComponent as WatchIcon } from '../assets/Icons/x-watch.svg';
import { ReactComponent as GlobeIcon } from '../assets/Icons/globe.svg';
import { ReactComponent as FileIcon } from '../assets/Icons/file-text.svg';
import { ReactComponent as ThermometerIcon } from '../assets/Icons/thermometer.svg';
import { ReactComponent as DollarSignIcon } from '../assets/Icons/dollar-sign.svg';

import { ReactComponent as UsersIcon } from '../assets/Icons/users.svg';
import { ReactComponent as FavUserIcon } from '../assets/Icons/user-favorites.svg';
import { ReactComponent as UserIcon } from '../assets/Icons/user.svg';
import { ReactComponent as InboxIcon } from '../assets/Icons/inbox.svg';
import { ReactComponent as SendIcon } from '../assets/Icons/send.svg';
import { colleagues, connections, favorites, received, sent } from "./common";
import { ReactComponent as UserCircleIcon } from '../assets/Icons/user-circle.svg';
import { ReactComponent as ShieldIcon } from '../assets/Icons/shield.svg';
import { ReactComponent as GraduationCapIcon } from '../assets/Icons/graduation-cap-profile.svg';
import { ReactComponent as UserAddIcon } from '../assets/Icons/user-add-plus-outlined.svg';
import { ReactComponent as BuildingIcon } from '../assets/Icons/building.svg';
import { ReactComponent as QuestionIcon } from '../assets/Icons/question.svg';
import { ReactComponent as ImageIcon } from '../assets/Icons/image.svg';
import { ReactComponent as RewardIcon } from '../assets/Icons/reward.svg';
import { ReactComponent as NotificationIcon } from '../assets/Icons/Notification.svg';
import { ReactComponent as ReferralOutlineIcon } from '../assets/Icons/referralOutline.svg';
import { ReactComponent as PostsIcon } from '../assets/Icons/posts.svg';
import { ReactComponent as ConnectionsIcon } from '../assets/Icons/connection.svg';

export const profileNavMenu = [
    { id: 1, path: paths.profileBasic, text: 'Basic', Icon: GridIcon },
    { id: 2, path: paths.profileDegree, text: 'Education', Icon: AwardIcon },
    { id: 9, path: paths.profileCredentials, text: 'Credentials', Icon: FileIcon },
    { id: 10, path: paths.profileTraining, text: 'Training', Icon: GraduationCapIcon },
    { id: 3, path: paths.profileAvailability, text: 'Office Hours', Icon: WatchIcon },
    { id: 4, path: paths.profileDemographics, text: 'Provider Demographics', Icon: GlobeIcon },
    { id: 5, path: paths.profileSpecialties, text: 'Specialties', Icon: FileIcon },
    { id: 6, path: paths.profileTreatment, text: 'Treatment Modalities', Icon: ThermometerIcon },
    { id: 7, path: paths.clientsServed, text: 'Clients Served', Icon: UsersIcon },
    { id: 8, path: paths.profileFees, text: 'Fees', Icon: DollarSignIcon },

]

export const networkNavMenu = [
    { id: 1, text: 'My Connections', path: paths.network(connections), Icon: UsersIcon },
    { id: 2, text: 'My Favorites', path: paths.network(favorites), Icon: FavUserIcon },
    { id: 3, text: 'All Providers', path: paths.network(colleagues), Icon: UserIcon },
]


export const referralNavMenu = [
    { id: 1, text: 'Received', path: paths.referrals(received), Icon: InboxIcon, countKey: 'received_count' },
    { id: 2, text: 'Sent', path: paths.referrals(sent), Icon: SendIcon, countKey: 'sent_count' },
]


export const settingsNavMenu = [
    { id: 1, text: 'Account', path: paths.settingsAccount, Icon: UserCircleIcon },
    { id: 2, text: 'Password', path: paths.settingsPassword, Icon: ShieldIcon },
    { id: 3, text: 'Invite friends', path: paths.settingsInvitations, Icon: RewardIcon },
    { id: 4, text: 'Notification', path: paths.settingsNotifications, Icon: NotificationIcon },

]


export const facilityProfileNavMenu = [
    { id: 1, path: paths.profileOrganization, text: 'Basic Information', Icon: BuildingIcon },
    { id: 8, path: paths.profileTeam, text: 'Team Invitations', Icon: UsersIcon },
    { id: 2, path: paths.profileAvailability, text: 'Availability', Icon: WatchIcon },
    // { id: 3, path: paths.profileDemographics, text: 'Demographics', Icon: GlobeIcon },
    { id: 3, path: paths.profileSpecialties, text: 'Specialties', Icon: FileIcon },
    { id: 4, path: paths.profileTreatment, text: 'Treatment Modalities', Icon: ThermometerIcon },
    { id: 10, path: paths.clientsServed, text: 'Ages served', Icon: UsersIcon },
    { id: 5, path: paths.profileServices, text: 'Services', Icon: UserAddIcon },
    { id: 6, path: paths.profileImages, text: 'Images', Icon: ImageIcon },
    { id: 9, path: paths.profileFees, text: 'Fees', Icon: DollarSignIcon },
    { id: 7, path: paths.profileFaqs, text: 'FAQs', Icon: QuestionIcon },
]

export const notificationNavMenu = [
    { id: 1, text: 'All Notifications', Icon: NotificationIcon, type:"All" },
    { id: 2, text: 'Referrals',  Icon: ReferralOutlineIcon, type:"Referral"},
    { id: 3, text: 'Posts',Icon: PostsIcon ,type : "Post" },
    { id: 4, text: 'Connection requests', Icon: ConnectionsIcon ,type:"Connection"}
]
