import React from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useUserProfile } from '../../../../contexts/UserProfile';
import AgeServed from './AgeServed';
import ClientTypes from './ClientTypes';
import { full_and_need_to_refer_out } from '../../../../constants/common'

const CenterOverview = () => {
  const { data } = useUserProfile();
  return (
    <Paper
      sx={{
        p: 3,
        mb: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5
      }}
    >
      <Typography variant='h5' fontWeight={600} mb={1.5}>Center Overview</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
        }}
      >
        <Typography variant='body1' fontWeight={600}>Treatment Type</Typography>
        <Box
          component='ul'
          sx={{
            columns: 2,
            pl: 2,
            m: 0,
          }}
        >
          {data?.info?.facility_types?.map((item) => (
            <Typography
              variant='body1'
              component='li'
              key={item.id}
              mb={1.5}
            >
              {item?.name}
            </Typography>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5
        }}
      >
        <Typography variant='body1' fontWeight={600}>Available Services</Typography>
        <Box
          component='ul'
          sx={{
            columns: 2,
            pl: 2,
            m: 0,
          }}
        >
          {data?.info?.available_services?.map((item) => (
            <Typography
              variant='body1'
              component='li'
              key={item.id}
              mb={1.5}
            >
              {item?.name}
            </Typography>
          ))}
        </Box>
      </Box>
      {
        (data?.info?.detox_for &&
        data?.info?.detox_for.length) ?
        (<Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5
          }}
        >
          <Typography variant='body1' fontWeight={600}>Detox For</Typography>
          <Box
            component='ul'
            sx={{
              columns: 2,
              pl: 2,
              m: 0,
            }}
          >
            {data?.info?.detox_for?.map((item) => (
              <Typography
                variant='body1'
                component='li'
                key={item.id}
                mb={1.5}
              >
                {item?.name}
              </Typography>
            ))}
          </Box>
        </Box>)
      : null}
      {(data.client_types?.length > 0 || data.info?.age_served) && (
        <Grid container columnSpacing={1} alignItems='stretch'>
          {data.info?.age_served && (
            <Grid item xs={12} sm={6}>
              <AgeServed />
            </Grid>
          )}
          {data.client_types?.length > 0 && (
            <Grid item xs={12} sm={6}>
              <ClientTypes />
            </Grid>
          )}
        </Grid>
      )}
    </Paper>
  )
}

export default CenterOverview