import React, { useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  Divider,
  Link,
  Paper,
  Badge,
  Button,
} from "@mui/material";
import { ReactComponent as CheckCircleIcon } from "../../assets/Icons/verify-outlined.svg";
import UpdateStatusMenu from "./UpdateStatusMenu";
import { useBasicInfo } from "../../contexts/BasicInfo";
import { ReactComponent as GroupIcon } from "../../assets/Icons/no-poverty.svg";
import {
  everyone,
  full_and_need_to_refer_out,
  onlyAcceptingClientsFrom,
  treatment_program,
} from "../../constants/common";
import { useGeneralProfile } from "../../contexts/Organization";
import { useAuth } from "../../contexts/Auth";
import sortAgeRanges from "../../utils/sortAges";
import paths from "../../constants/paths";

const TherapistInfo = () => {
  const { data } = useBasicInfo();
  const { facilityInfo } = useGeneralProfile();
  const { account_type } = useAuth();
  const [openStatusDrawer, setOpenStatusDrawer] = useState(false);

  const info = account_type === treatment_program ? facilityInfo : data;
  const isMentalHealthProvider =
    account_type === treatment_program &&
    info?.roles?.includes("mental_health_provider");
  const ageServed =
    info?.info?.age_served_currently?.length > 0
      ? sortAgeRanges(info.info.age_served_currently.split(",")).join(",")
      : "";

  const handleOpenStatusDrawer = (ev) => {
    ev?.stopPropagation();
    setOpenStatusDrawer(true);
  };

  const handleCloseStatusDrawer = () => setOpenStatusDrawer(false);

  return (
    <Paper sx={{ py: 3 }}>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          px: 2,
        }}
      >
        <Badge
          overlap="circular"
          color={
            info?.info?.current_status === "not_accepting_clients"
              ? "error"
              : "success"
          }
          badgeContent=" "
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{ mb: 1.5, "& .MuiBadge-badge": { border: "1px solid white" } }}
        >
          <Avatar sx={{ width: 72, height: 72 }} src={info?.main_image} />
        </Badge>
        <Link
          href={paths.userProfile(info?.uuid, info?.account_type)}
          variant="h5"
          mb={1.5}
          color="text.primary"
          textTransform="capitalize"
        >
          {account_type === treatment_program
            ? facilityInfo?.info?.facility_name
            : `${data.first_name || ""} ${data.last_name || ""}`}
        </Link>
        {data?.info?.headline && account_type !== treatment_program && (
          <Typography variant="body2" mb={1.25}>
            {data?.info?.headline}
          </Typography>
        )}
        {info?.email_verified_at && (
          <Typography
            variant="body2"
            color="success.main"
            display="flex"
            alignItems="center"
            fontWeight={600}
            gap={0.5}
          >
            <CheckCircleIcon />
            Verified Account
          </Typography>
        )}
      </Box>
      <Divider sx={{ my: 1.5 }} />
      <Box px={1.5}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 1.5,
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: "text.secondary" }}
            fontWeight={600}
          >
            My Availability
          </Typography>
          <Button
            color="primary"
            size="small"
            onClick={handleOpenStatusDrawer}
            sx={{ ml: "auto", p: 0, minWidth: 0 }}
            disabled={!info?.uuid || isMentalHealthProvider}
          >
            Edit
          </Button>
        </Box>
        <UpdateStatusMenu
          openStatusDrawer={openStatusDrawer}
          handleOpenStatusDrawer={handleOpenStatusDrawer}
          handleCloseStatusDrawer={handleCloseStatusDrawer}
          disabled={!info?.uuid || isMentalHealthProvider}
        />
          {info?.info?.current_status !== full_and_need_to_refer_out && (
            <>
              {info?.currently_served?.length > 0 && (
                <Paper sx={{ my: 2 }}>
                  <Box sx={{ py: 0.5, px: 2 }}>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      color="text.secondary"
                    >
                      Types
                    </Typography>
                  </Box>
                  <Divider />
                  <Divider />
                  <Box
                    sx={{
                      py: 1,
                      px: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: 1.5,
                      overflow: "wrap",
                      wordBreak: "break-all",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ wordBreak: "break-word" }}
                    >
                      {info.currently_served
                        .map((item) => item?.name)
                        .join(", ")}
                    </Typography>
                  </Box>
                </Paper>
              )}
              {info?.info?.age_served_currently && (
                <Paper sx={{ my: 2 }}>
                  <Box sx={{ py: 0.5, px: 2 }}>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      color="text.secondary"
                    >
                      Ages
                    </Typography>
                  </Box>
                  <Divider />
                  <Divider />
                  <Box
                    sx={{
                      py: 1,
                      px: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: 1.5,
                      overflow: "wrap",
                      wordBreak: "break-all",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ wordBreak: "break-word" }}
                    >
                      {ageServed.split(",").filter(Boolean).join(", ")}
                    </Typography>
                  </Box>
                </Paper>
              )}
              {info?.info?.accepting_referrals_from && (
                <Paper sx={{ my: 2 }}>
                  <Box sx={{ py: 0.5, px: 2 }}>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      color="text.secondary"
                    >
                      Accepting From
                    </Typography>
                  </Box>
                  <Divider />
                  <Divider />
                  <Box
                    sx={{
                      py: 1,
                      px: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: 1.5,
                      overflow: "wrap",
                      wordBreak: "break-all",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ wordBreak: "break-word" }}
                    >
                      {
                        onlyAcceptingClientsFrom[
                          info?.info?.accepting_referrals_from
                        ]
                      }
                    </Typography>
                  </Box>
                </Paper>
              )}
            </>
          )}
      </Box>
    </Paper>
  );
};

export default TherapistInfo;
