import React, { useEffect, useState } from 'react'
import moment from 'moment';
import MainLayout from '../../layouts/MainLayout'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import LoadingButton from '@mui/lab/LoadingButton';

import SideMenu from '../../components/SideMenu';
import { referralNavMenu } from '../../constants/NavMenus';
// import { ReactComponent as FilterIcon } from '../../assets/Icons/filter.svg';
import { ReactComponent as SearchIcon } from '../../assets/Icons/search-large.svg';
import { ReactComponent as PhoneIcon } from '../../assets/Icons/phone.svg';
import { useReferrals } from '../../contexts/Referral';
import ReferalCard from '../../components/ReferralCard/inbex';
import { LOADING, SUCCESS, received, sent, treatment_program } from '../../constants/common';
import InfiniteScroll from 'react-infinite-scroll-component';
import paths from '../../constants/paths';
import { useParams, useLocation } from 'react-router-dom';
import EmptyState from './EmptyState';
import { useBasicInfo } from '../../contexts/BasicInfo';
import { useAuth } from '../../contexts/Auth';

let timer; 

const Referral = () => {
    const {
        referrals,
        fetchReferrals,
        acceptReferralStatus,
        rejectReferralStatus,
        acceptReferal,
        rejectReferal,
        updateReferralsState,
        markReferralAsRead,
        fetchStatus,
        fetchReferralsCount,
        count,
    } = useReferrals();
    const { account_type } = useAuth();
    const { data: userInfo } = useBasicInfo();
    const [activeReferral, setActiveReferral] = useState({});
    const [search, setSearch] = useState('');
    const [params, setParams] = useState({
        page: 1,
        search: '',
        account_type: account_type === treatment_program ? treatment_program : 'user'
    })

    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const referralValue = queryParams.get('refferal');
    
    function fetchNexPage() {
        setParams((prevState) => ({
            ...prevState,
            page: prevState.page + 1
        }));
    }

    function handleSearch(ev) {
        setSearch(ev.target.value)
    }

    function handleAcceptReferral() {
        acceptReferal({ account_type: account_type === treatment_program ? treatment_program : 'user', referral_id: activeReferral?.id });
    }

    function handleRejectReferral() {
        rejectReferal({ account_type: account_type === treatment_program ? treatment_program : 'user', referral_id: activeReferral?.id });
    }

    useEffect(() => {
        fetchReferrals(params, id);
    }, [params])
    
    useEffect(() => {
        if (search.length > 2) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                setParams((prevState) => ({
                    ...prevState,
                    search,
                    page: 1,
                }))
            }, 1000);
        }
    }, [search])

    useEffect(() => {
        setActiveReferral({});
        setParams((prevState) => ({
            ...prevState,
            page: 1,
            search: ''
        }));
        setSearch('');
    }, [id])

    useEffect(() => {
        if (acceptReferralStatus === SUCCESS) {
            fetchReferrals(params, id);
            updateReferralsState('acceptReferralStatus', '')
            if (referrals.data.length > 0) {
                setActiveReferral((prevState) => ({
                    ...prevState,
                    status: 'accepted'
                }))
            } else {
                setActiveReferral({})
            }
            
        }
    }, [acceptReferralStatus])

    useEffect(() => {
        if (rejectReferralStatus === SUCCESS) {
            fetchReferrals(params, id);
            updateReferralsState('rejectReferralStatus', '')
            setActiveReferral({})
        }
    }, [rejectReferralStatus])
    

    useEffect(() => {
        if (fetchStatus === SUCCESS) {
            updateReferralsState('fetchStatus', '')
            if (referrals?.data?.length > 0 && !activeReferral?.id) {
                setActiveReferral(referrals?.data[0]);
                if (referrals?.data[0] && id === received) {
                    if (referrals?.data[0].status === "unseen")
                        markReferralAsRead(referrals?.data[0].id)
                }
            }
        }
      
    }, [referrals?.data, activeReferral, fetchStatus, id])

    useEffect(() => {
        fetchReferralsCount({ account_type: account_type === treatment_program ? treatment_program : 'user' });
    }, [])

    useEffect(() => {
        if (referrals.data?.length > 0 && referralValue){
            const referralValueData = referrals?.data.find(item => item.id == referralValue)
            if (referralValueData) {
                setActiveReferral(referralValueData)
                if (fetchStatus === SUCCESS && referralValueData.status === "unseen") {
                    markReferralAsRead(referralValueData?.id)
                }
            }
      }
    }, [referralValue,referrals?.data])
    
    const isTreatmentProgram =  id === received ? activeReferral?.referrable_type === "App\\Models\\TreatmentProgram" : activeReferral?.referral_user_type === "App\\Models\\TreatmentProgram";

    return (
        <MainLayout
            currentPage='Referrals'
        >
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={2}>
                        <SideMenu
                            menu={referralNavMenu}
                            count={count}
                            withBackground
                        />
                    </Grid>
                    <Grid item xs={12} md={10}>
                        {referrals.data?.length > 0 ? (
                            <>
                                <Grid
                                    container
                                    spacing={0}
                                    sx={{
                                        border: '1px solid #F1F2F3',
                                        borderRadius: 1.5,
                                        backgroundColor: 'background.paper',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Grid item xs={4.6}>
                                        <Box
                                            sx={{
                                                boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
                                                borderTopLeftRadius: 1.5,
                                                borderBottomLeftRadius: 1.5,
                                                backgroundColor: 'background.paper',
                                                height: '100%'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    p: 3,
                                                    mb: 1
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        mb: 1.5
                                                    }}
                                                >
                                                    <Typography variant='body2' fontWeight={600}>
                                                        {id === received ? 'Received' : 'Sent'} Referrals
                                                    </Typography>
                                                    {/* <IconButton sx={{ p: 0.375 }}>
                                                        <FilterIcon />
                                                    </IconButton> */}
                                                </Box>
                                                <TextField
                                                    fullWidth
                                                    placeholder='Search In Referrals'
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position='start' sx={{ mr: 0 }}>
                                                                <SearchIcon width={24} height={24} />
                                                            </InputAdornment>
                                                        ),
                                                        sx: {
                                                            pl: 1,
                                                        }
                                                    }}
                                                    sx={{
                                                        'input:not(textarea)': {
                                                        py: 1.6,
                                                        px: 1,
                                                        fontSize: 14,
                                                        lineHeight: '22px',
                                                        '&::placeholder': {
                                                            fontWeight: 700
                                                        }
                                                        }
                                                    }}
                                                    onChange={handleSearch}
                                                    value={search}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    height: `calc(100vh - ${Boolean(userInfo?.email) ? '358px' : '308px'})`,
                                                    overflow: 'auto'
                                                }}
                                                id='referals-list'
                                            >
                                                {/* <InfiniteScroll
                                                    dataLength={params.page * (referrals.per_page || 0)} //This is important field to render the next data
                                                    next={fetchNexPage}
                                                    hasMore={params.page < referrals.last_page && fetchStatus !== LOADING}
                                                    scrollableTarget='referals-list'
                                                    style={{
                                                        overflow: 'visible'
                                                    }}
                                                    loader={
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                        >
                                                            <CircularProgress />
                                                        </Box>
                                                    }
                                                    endMessage={
                                                        <Box>
                                                        </Box>
                                                    }
                                                >
                                                {fetchStatus === LOADING && params.page === 1 ? (
                                                    <Box
                                                        sx={{
                                                            px: 3,
                                                            borderLeft: '4px solid transparent',
                                                            mb: 1,
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <Skeleton animation='wave' sx={{ mb: 2 }} />
                                                        <Skeleton animation='wave' sx={{ mb: 2 }} />
                                                        <Skeleton animation='wave' sx={{ mb: 2 }} />
                                                        <Skeleton animation='wave' sx={{ mb: 2 }} />
                                                        <Skeleton animation='wave' sx={{ mb: 2 }} />
                                                        <Skeleton animation='wave' sx={{ mb: 2 }} />
                                                    </Box>
                                                ) : ( */}
                                                    {referrals.data.map((item) => (
                                                        <ReferalCard
                                                            data={item}
                                                            key={item.id}
                                                            setActiveReferral={setActiveReferral}
                                                            isActive={item.id === activeReferral?.id}
                                                            tabId={id}
                                                        />
                                                    ))}
                                                {/* )}
                                                </InfiniteScroll> */}
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={7.4}>
                                        <Box
                                            sx={{
                                                pl: 6,
                                                pr: 3,
                                                py: 3
                                            }}
                                        >
                                            <Typography variant='body2' fontWeight={600}>
                                                {id === received ? 'Received' : 'Sent'} Referral Details
                                            </Typography>
                                            <Divider sx={{ my: 1.5 }} />
                                            {Boolean(activeReferral?.id) && (
                                                <>
                                                    <Box
                                                        sx={{
                                                            border: '1px solid',
                                                            borderColor: 'border.main',
                                                            p: 3,
                                                            borderRadius: 1.5,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            mb: 1.5
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: 1,
                                                                flexGrow: 1
                                                            }}
                                                        >
                                                            <Avatar
                                                                sx={{
                                                                    width: 64,
                                                                    height: 64,
                                                                }}
                                                                src={isTreatmentProgram ? activeReferral?.facility_image : activeReferral?.profile_image}
                                                            />
                                                            <Box flexGrow={1}>
                                                                {/* <Link href={paths.userProfile(activeReferral?.uuid, isTreatmentProgram ? treatment_program : 'user')} variant='body2' color='text.primary' fontWeight={700} textTransform='capitalize'> */}
                                                                <Typography  variant='body2' color='text.primary' fontWeight={700} textTransform='capitalize'>
                                                                    {isTreatmentProgram ? activeReferral?.facility_name : activeReferral?.name}
                                                                </Typography>
                                                                {/* </Link> */}
                                                                <Typography variant='caption' color='text.secondary' component='p' lineHeight='18px'>{activeReferral?.headline}</Typography>
                                                                {Boolean(activeReferral?.connection_count) && <Typography variant='caption' color='text.secondary' component='p' lineHeight='18px'>{activeReferral?.connection_count} Connection</Typography>}
                                                            </Box>
                                                        </Box>
                                                        {/* {activeReferral[sideMenuItem.id === 2 ? 'referral_user' : 'user']?.info?.phone_number && (
                                                            <Button
                                                                startIcon={<PhoneIcon />}
                                                                size='small'
                                                                sx={{
                                                                    px: 2,
                                                                    backgroundColor: 'primary.light'
                                                                }}
                                                            >
                                                                {activeReferral[sideMenuItem.id === 2 ? 'referral_user' : 'user']?.info?.phone_number}
                                                            </Button>
                                                        )} */}
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            p: 1,
                                                            mb: 1.5
                                                        }}
                                                    >
                                                        <Typography variant='body2' mb={1}>{moment(activeReferral?.created_at).format('DD MMM YYYY')}</Typography>
                                                        <Typography variant='h4' mb={1} maxWidth={451}>{activeReferral?.subject}</Typography>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            p: 1,
                                                            mb: 1.5,
                                                        }}
                                                    >
                                                        <Typography variant='body1'>
                                                            {activeReferral?.message}
                                                        </Typography>
                                                    </Box>
                                                    {id === received && !['rejected', 'accepted'].includes(activeReferral?.status) && (
                                                        <>
                                                            <Divider sx={{ my: 1.5 }} />
                                                            <Box
                                                                sx={{
                                                                    py: 3
                                                                }}
                                                            >
                                                                <Typography variant='body2' mb={2}>Interested In This Referral?</Typography>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={6}>
                                                                        <LoadingButton
                                                                            variant='contained'
                                                                            size='large'
                                                                            fullWidth
                                                                            onClick={handleAcceptReferral}
                                                                            loading={acceptReferralStatus === LOADING}
                                                                        >
                                                                            This is a good fit
                                                                        </LoadingButton>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <LoadingButton
                                                                            color='secondary'
                                                                            variant='outlined'
                                                                            size='large'
                                                                            fullWidth
                                                                            sx={{
                                                                                color: 'primary.main'
                                                                            }}
                                                                            onClick={handleRejectReferral}
                                                                            loading={rejectReferralStatus === LOADING}
                                                                        >
                                                                            Not a good fit
                                                                        </LoadingButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <EmptyState />
                        )}
                        
                    </Grid>
                </Grid>
            </Container>
        </MainLayout>
    )
}

export default Referral;