import { axiosInstance } from "./axiosInstance";

function getSpecialties(params) {
    return axiosInstance.get('specialties', { params });
}

function updateSpecialties(data) {
    return axiosInstance.post('specialties', data);
}

function getUserSpecialties(params) {
    return axiosInstance.get('specialties/user', { params });
}

export {
    getSpecialties,
    updateSpecialties,
    getUserSpecialties
}