import React, { createContext, useContext, useEffect, useState } from 'react';
import { deleteProfilePhoto, getInfo, updateAboutMe, updateAddress, updateAvailability, updateBasicInfo, updateContact, updateLinksInfo, updateOfficeInfo, updateProfilePhoto } from '../services/basicInfo.services';
import { ERROR, LOADING, SUCCESS } from '../constants/common';
import { useAlert } from './Alert';
import { useAuth } from './Auth';


const BasicInfoContext = createContext({});

export const BasicInfoContextProvider = ({ children }) => {
  const { sendAlert } = useAlert();
  const [basicInfoState, setInfoState] = useState({
    fetchStatus: '',
    updateBasicInfoStatus: '',
    updateAboutMeStatus: '',
    updateContactStatus: '',
    updateOfficeInfoStatus: '',
    updateAddressStatus: '',
    updateLinkstatus: '',
    updatePhotoStatus: '',
    updateAvailabilityStatus: '',
    data: {}
  });

  const { isLoggedIn } = useAuth();

  function handleState(obj) {
    setInfoState((prevState) => ({
      ...prevState,
      ...obj
    }))
  }

  async function fetchInfo() {
    handleState({ fetchStatus: LOADING })
    try {
      const response = await getInfo();
      handleState({
        data: response.data.data || {},
        fetchStatus: SUCCESS
      })
    } catch (error) {
      handleState({ fetchStatus: ERROR })
      Promise.reject(error)
    }
  }

  async function editBasicInfo(data) {
    handleState({ updateBasicInfoStatus: LOADING })
    try {
      await updateBasicInfo(data);
      handleState({
        updateBasicInfoStatus: SUCCESS
      })
      sendAlert('Updated successfully', 'success')
    } catch (error) {
      handleState({ updateBasicInfoStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  async function editAboutMe(data) {
    handleState({ updateAboutMeStatus: LOADING })
    try {
      await updateAboutMe(data);
      handleState({
        updateAboutMeStatus: SUCCESS
      })
      sendAlert('Updated successfully', 'success')
    } catch (error) {
      handleState({ updateAboutMeStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  async function editContact(data) {
    handleState({ updateContactStatus: LOADING })
    try {
      await updateContact(data);
      handleState({
        updateContactStatus: SUCCESS
      })
      sendAlert('Updated successfully', 'success')
    } catch (error) {
      handleState({ updateContactStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  async function editOfficeInfo(data) {
    handleState({ updateOfficeInfoStatus: LOADING })
    try {
      await updateOfficeInfo(data);
      handleState({
        updateOfficeInfoStatus: SUCCESS
      })
      sendAlert('Updated successfully', 'success')
    } catch (error) {
      handleState({ updateOfficeInfoStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  async function editAddress(data) {
    handleState({ updateAddressStatus: LOADING })
    try {
      await updateAddress(data);
      handleState({
        updateAddressStatus: SUCCESS
      })
      sendAlert('Updated successfully', 'success')
    } catch (error) {
      handleState({ updateAddressStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  async function editLinksInfo(data) {
    handleState({ updateLinkstatus: LOADING })
    try {
      await updateLinksInfo(data);
      handleState({
        updateLinkstatus: SUCCESS
      })
      sendAlert('Updated successfully', 'success')
    } catch (error) {
      handleState({ updateLinkstatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  async function editProfilePhoto(data) {
    handleState({ updatePhotoStatus: LOADING })
    try {
      await updateProfilePhoto(data);
      handleState({
        updatePhotoStatus: SUCCESS
      })
      sendAlert('Updated successfully', 'success')
    } catch (error) {
      handleState({ updatePhotoStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  async function removeProfilePhoto() {
    handleState({ updatePhotoStatus: LOADING });
    try {
      await deleteProfilePhoto();
      handleState({
        updatePhotoStatus: SUCCESS
      })
      sendAlert('Removed successfully', 'success');
    } catch (error) {
      handleState({ updatePhotoStatus: ERROR });
      sendAlert(error.response?.data?.message, 'error');
      Promise.reject(error);
    }
  }

  async function editAvailability(data) {
    handleState({ updateAvailabilityStatus: LOADING })
    try {
      await updateAvailability(data);
      handleState({
        updateAvailabilityStatus: SUCCESS
      })
      sendAlert('Updated successfully', 'success')
    } catch (error) {
      handleState({ updateAvailabilityStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  function updateInfoState(field, value) {
    handleState({ [field]: value })
  }

  useEffect(() => {
    handleState({
      data: {},
    })
  }, [isLoggedIn])



  const state = {
    ...basicInfoState,
    fetchInfo,
    editBasicInfo,
    editAboutMe,
    editContact,
    editOfficeInfo,
    editAddress,
    editLinksInfo,
    editProfilePhoto,
    removeProfilePhoto,
    editAvailability,
    updateInfoState
  };

  return (
    <BasicInfoContext.Provider value={state}>
      {children}
    </BasicInfoContext.Provider>
  );
};

export const useBasicInfo = () => useContext(BasicInfoContext);