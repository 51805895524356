import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { ReactComponent as CheckIcon } from '../../../../assets/Icons/check-filled.svg';
import { useUserProfile } from '../../../../contexts/UserProfile';

const PAYMENT_OPTIONS = [
  { key: 'private_health_insurance', label: 'Private health insurance' },
  { key: 'private_pay', label: 'Private pay' },
  { key: 'free', label: 'Free' },
  { key: 'medicare', label: 'Medicare' },
  { key: 'medicaid', label: 'Medicaid' },
  { key: 'ihs-funded', label: 'IHS-funded' },
  { key: 'tricare', label: 'TRICARE' },
  { key: 'state_insurance', label: 'State insurance' },
  { key: 'sliding_scale', label: 'Sliding fee scale' },
  { key: 'payment_assistance', label: 'Payment assistance' },
];

const PaymentInformation = () => {
  const { data } = useUserProfile();
  const paymentOptions = data?.info?.payment_options || [];

  const checkIfAccepted = (key) => paymentOptions.some((option) => option.key === key);

  const cellStyles = {
    borderRight: '1px solid',
    borderColor: 'secondary.main',
    textAlign: 'center',
    color: 'primary.main',
  };
  const headerStyles = {
    borderRight: '1px solid',
    borderColor: 'secondary.main',
    textAlign: 'center',
  };

  return (
    <Paper sx={{ p: 3, mb: 2 }}>
      <Typography variant='h5' fontWeight={600} mb={3}>
        Payment Information
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ pl: 0, borderRight: '1px solid', borderColor: 'secondary.main' }}>
              <Typography variant='body1' fontWeight={600}>
                Type
              </Typography>
            </TableCell>
            <TableCell sx={headerStyles}>
              <Typography variant='body1' fontWeight={600}>
                Accepted
              </Typography>
            </TableCell>
            <TableCell sx={headerStyles}>
              <Typography variant='body1' fontWeight={600}>
                Not Accepted
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {PAYMENT_OPTIONS.map((option) => {
            const isAccepted = checkIfAccepted(option.key);
            return (
              <TableRow key={option.key}>
                <TableCell sx={{ pl: 0, borderRight: '1px solid', borderColor: 'secondary.main'}}>
                  <Typography variant='body1'>{option.label}</Typography>
                </TableCell>
                <TableCell sx={cellStyles}>
                  {isAccepted && <CheckIcon />}
                </TableCell>
                <TableCell sx={cellStyles}>
                  {!isAccepted && <CheckIcon />}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default PaymentInformation;
