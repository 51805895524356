import React, { createContext, useContext, useState } from 'react';
import { getTreatmentModalities, getUserTreatmentModalities, updateMedicationPrescriber, updateTreatmentModalities } from '../services/treatmentModalities.services';
import { ERROR, LOADING, SUCCESS } from '../constants/common';
import { useAlert } from './Alert';
import TreatmentDrawer from '../components/TreatmentDrawer';

const TreatmentContext = createContext({});

export const TreatmentContextProvider = ({ children }) => {
  const { sendAlert } = useAlert();
    const [stateData, setStateData] = useState({
        treatmentModalities: [],
        userTreatmentModalities: [],
        fetchStatus: '',
        createStatus: '',
        fetchTreatmentModalitiesStatus: '',
        clientTypes: [],
        fetchClientTypesStatus: '',
        updateClientTypesStatus: '',
        userClientTypes: [],
        fetchUserClientTypesStatus: '',
        updateMedicationPrescriberStatus: '',
    });

    const [open, setOpen] = useState(false);

    function handleOpenDrawer() {
      setOpen(true)
    }

    function handleCloseDrawer() {
        setOpen(false)
    }

    function handleState(obj) {
        setStateData((prevState) => ({
            ...prevState,
            ...obj
        }))
    }
    
    async function fetchTreatmentModalities(data) {
      try {
        handleState({ fetchStatus: LOADING })
        const response = await getTreatmentModalities(data);
        handleState({
            treatmentModalities: response.data.data,
            fetchStatus: SUCCESS
        })
      } catch (error) {
        handleState({ fetchStatus: ERROR })
        Promise.reject(error)
      }
    }

    async function editTreatmentModalities(data) {
        handleState({ createStatus: LOADING })
      try {
        await updateTreatmentModalities(data);
        handleState({
            createStatus: SUCCESS
        })
        sendAlert('Updated treatment modalities successfully', 'success')
      } catch (error) {
        handleState({ createStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }
 
    async function fetchUserTreatmentModalities(data) {
        handleState({ fetchTreatmentModalitiesStatus: LOADING })
      try {
        const response = await getUserTreatmentModalities(data);
        handleState({
            userTreatmentModalities: response.data.data,
            fetchTreatmentModalitiesStatus: SUCCESS
        })
      } catch (error) {
        handleState({ fetchTreatmentModalitiesStatus: ERROR })
        Promise.reject(error)
      }
    }

    

    async function editMedicationPrescriber(data) {
      handleState({ updateMedicationPrescriberStatus: LOADING })
      try {
          await updateMedicationPrescriber(data);
          handleState({
              updateMedicationPrescriberStatus: SUCCESS
          })
          sendAlert('Updated successfully', 'success')
      } catch (error) {
          handleState({ updateMedicationPrescriberStatus: ERROR })
          sendAlert(error.response?.data?.message, 'error')
          Promise.reject(error)
      }
    }

    function updateTreatmentModalitiesState(field, value) {
      handleState({ [field]: value })
    }

    const state = {
        ...stateData,
        fetchTreatmentModalities,
        updateTreatmentModalities,
        editTreatmentModalities,
        fetchUserTreatmentModalities,
        updateTreatmentModalitiesState,
        editMedicationPrescriber,
        handleOpenDrawer,
        handleCloseDrawer,
        open
    };

    return (
        <TreatmentContext.Provider value={state}>
          {children}
          <TreatmentDrawer
            open={open}
            onClose={handleCloseDrawer}
          />

        </TreatmentContext.Provider>
    );
};

export const useTreatment = () => useContext(TreatmentContext);