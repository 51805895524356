import debounce from 'lodash.debounce';

export const convertObjectToFormData = (obj) => {
  const formData = new FormData();

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(`${key}[]`, item);
      });
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};

export const formatUrl = (url) => {
  if (!url) return '';
  url = url.trim();
  if (!/^https?:\/\//i.test(url)) {
    url = `https://${url}`;
  }
  return url;
};
export const validateImageDimensions = (file) => {
  return new Promise((resolve) => {
    const img = new Image();
    const objectURL = URL.createObjectURL(file);

    img.onload = () => {
      const { width, height } = img;
      URL.revokeObjectURL(objectURL);

      if (width < 300 || height < 300) {
        resolve(
          'Image dimensions are too small. Minimum size is 300x300 pixels.'
        );
      } else if (width > 2000 || height > 2000) {
        resolve(
          'Image dimensions are too large. Maximum size is 2000x2000 pixels.'
        );
      } else {
        resolve(true);
      }
    };

    img.onerror = () => {
      console.error('Error loading image.');
      URL.revokeObjectURL(objectURL);
      resolve('Invalid image file. Could not load.');
    };

    img.src = objectURL;
  });
};
export const fileSize = (size) => {
  const maxSizeInBytes = 10 * 1024 * 1024;
  if (size > maxSizeInBytes) {
    return true;
  }
  return false;
};


export const validatePromoCity = (city) => {
  return ['cleveland', 'akron-canton', 'youngstown'].includes(city);
}

export const debouncedTrigger = debounce((field, trigger) => {
  trigger(field);
}, 100);
