import React, { createContext, useContext, useState } from 'react';
import { getSpecialties, getUserSpecialties, updateSpecialties } from '../services/specialties.services';
import { ERROR, LOADING, sortData, SUCCESS } from '../constants/common';
import { useAlert } from './Alert';
import SpecialtiesDrawer from '../components/SpecialtiesDrawer';

const SpecialtiesContext = createContext({});

export const SpecialtiesContextProvider = ({ children }) => {
  const { sendAlert } = useAlert();
    const [specialties, setSpecialties] = useState({
        list: [],
        userSpecialties: [],
        fetchStatus: '',
        createStatus: '',
        fetchSpecialtiesStatus: ''
    });

    const [open, setOpen] = useState(false);

    function handleOpenDrawer() {
      setOpen(true)
    }

    function handleCloseDrawer() {
      setOpen(false)
    }
    
    function handleState(obj) {
        setSpecialties((prevState) => ({
            ...prevState,
            ...obj
        }))
    }
    
    async function fetchSpecialties(data) {
      try {
        handleState({ fetchStatus: LOADING })
        const response = await getSpecialties(data);
        handleState({
            list: sortData(response.data.data),
            fetchStatus: SUCCESS
        })
      } catch (error) {
        handleState({ fetchStatus: ERROR })
        Promise.reject(error)
      }
    }

    async function editSpecialties(data) {
        handleState({ createStatus: LOADING })
      try {
        await updateSpecialties(data);
        handleState({
            createStatus: SUCCESS
        })
        sendAlert('Updated sepcialties successfully', 'success')
      } catch (error) {
        handleState({ createStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }
 
    async function fetchUserSpecialties(user_id) {
        handleState({ fetchSpecialtiesStatus: LOADING })
      try {
        const response = await getUserSpecialties(user_id);
        handleState({
            userSpecialties: response.data.data,
            fetchSpecialtiesStatus: SUCCESS
        })
      } catch (error) {
        handleState({ fetchSpecialtiesStatus: ERROR })
        Promise.reject(error)
      }
    }

    function updateSpecialtiesState(field, value) {
        handleState({ [field]: value })
    }
    

    const state = {
        specialties,
        fetchSpecialties,
        updateSpecialties,
        editSpecialties,
        fetchUserSpecialties,
        handleOpenDrawer,
        handleCloseDrawer,
        open,
        updateSpecialtiesState
    };

    return (
        <SpecialtiesContext.Provider value={state}>
          {children}

          <SpecialtiesDrawer
              open={open}
              onClose={handleCloseDrawer}
          />
        </SpecialtiesContext.Provider>
    );
};

export const useSpecialties = () => useContext(SpecialtiesContext);